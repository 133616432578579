const LOCAL_STORAGE_KEY = 'hiddenAnnouncements';

function getPreviouslyHiddenAnnouncements(): string[] {
  const idList = localStorage.getItem(LOCAL_STORAGE_KEY) || '';
  return idList.split(',');
}

function saveHiddenAnnouncement(id: string) {
  const previouslyHidden = getPreviouslyHiddenAnnouncements();
  if (previouslyHidden.includes(id)) {
    return;
  }

  localStorage.setItem(LOCAL_STORAGE_KEY,
      [...previouslyHidden, id].filter(Boolean).join(','));
}

function hideAnnouncementFromDom(id: string) {
  const announcement = document.getElementById('notice_' + id);
  if (!announcement || !announcement.parentNode) {
    /* If this happens, there is a bug. */
    console.error('Valid announcement not found! ID:', id);
    return;
  }
  announcement.parentNode.removeChild(announcement);
}

function hideAnnouncement(id: string) {
  saveHiddenAnnouncement(id);
  hideAnnouncementFromDom(id);
}

function addCloseHandlers(container: HTMLElement) {
  container.querySelectorAll<HTMLElement>('.js-announcement-close').forEach(
    (button) => {
      button.addEventListener('click', function() {
        const announcementId = button.dataset.announcementId;
        if (announcementId) {
          hideAnnouncement(announcementId);
        }
      });
    }
  );
}

function getAnnouncementsHtml(announcements: {id: number, html: string}[]) {
  if (!announcements) {
    return '';
  }

  const previouslyHidden = getPreviouslyHiddenAnnouncements();
  const activeAnnouncements = announcements.filter((announcement) => (
    !previouslyHidden.includes(String(announcement.id))
  ));

  return activeAnnouncements.map(announcement => announcement.html).join('');
}

(function insertAnnouncements() {
  const container = document.getElementById('js-announcements');
  if (!container) {
    return;
  }

  const xhr = new XMLHttpRequest();
  xhr.open('GET', '/.announcements?v=2');
  xhr.onload = function() {
    try {
      const data = JSON.parse(xhr.responseText);
      const announcements = getAnnouncementsHtml(data && data.announcements);
      if (announcements.length < 1) {
        return;
      }
      container.innerHTML = announcements;
      addCloseHandlers(container);
    } catch(e) {
      console.error(e);
    }
  };
  xhr.send();
})();
